@import '../../../style.less';
.deleteIconContainer {
  background-color: @error-color !important;
  .deleteIcon {
    cursor: pointer;
  }
}

.imageContainer {
  flex-wrap: wrap;
  display: flex;
  .imageBox {
    height: 100px;
    width: 100px;
    border: 1px solid @border-color-base;
    padding: 10px;
    position: relative;
    margin: 0 10px 10px 0;
    img {
      width: 80px;
    }
    .imageCover {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      color: #fff;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      .imageActionContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .imageAction {
          margin: 0 5px;
          cursor: pointer;
        }
      }
      opacity: 0;
      transition: all 0.5s;
      z-index: 1;
      &:hover {
        opacity: 1;
      }
    }
  }
}

@border-color-base: #e5e6eb;@layout-body-background: #f5f6fa;@primary-color: #4c84ff;@table-selected-row-bg: #f5f6fa;