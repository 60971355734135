.table {
  width: 100%;
  border: 1px solid #e8e8e8;
  line-height: 1.5;
  font-size: 14px;
  margin: 1em 0;
  text-align: center;
  background-color: #fff;
}
.table td,
.table th {
  border: 1px solid #e8e8e8;
  padding: 0.6em;
}
.table th,
.table td.th {
  background: rgba(0, 0, 0, 0.02);
  font-weight: bold;
  color: #5c6b77;
}
.table tr.compact td {
  padding: 5px;
}
.fakeInput {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  border-radius: 4px;
  cursor: pointer;
  transition: border 0.3s, background-color 0.3s;
  border: 1px solid rgba(255, 255, 255, 0);
}
.fakeInput:hover {
  border: 1px solid #e5e6eb;
  background-color: #fff;
}
.fakeInput.small {
  height: 24px;
  padding: 1px 7px;
}
.configRowGroup .cover {
  left: 0;
  position: absolute;
  transform: scale(1);
  width: 100%;
  transition: opacity 0.3s;
}
.configRowGroup .cover td {
  border: none;
  padding: 6px;
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  text-align: right;
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
}
.configRowGroup .cover td.blank {
  background: none;
}
.configRowGroup:hover .cover {
  opacity: 0;
}
.configRowGroup:hover .cover:hover {
  opacity: 1;
}
.container {
  text-align: center;
}
.previewContainer {
  position: relative;
}
.previewContainer .outerRadioContainer {
  z-index: 1;
}
.previewContainer .outerRadioContainer.inset {
  position: absolute;
  top: 8px;
  left: 8px;
}
.previewContainer .outerRadioContainer .radioContainer {
  text-align: left;
}
.previewContainer .outerRadioContainer .radioContainer :global(.ant-radio-button-wrapper) {
  background: rgba(255, 255, 255, 0.3);
}
.previewContainer .outerRadioContainer .radioContainer .radioGroup {
  margin-right: 8px;
  margin-bottom: 8px;
  background: none;
}
.previewContainer .outerRadioContainer .radioContainer .radioGroup.danger :global(.ant-radio-button-wrapper):hover {
  color: #f5222d;
}
.previewContainer .outerRadioContainer .radioContainer .radioGroup.danger :global(.ant-radio-button-wrapper-checked) {
  border-color: #f5222d;
  color: #f5222d;
  box-shadow: -1px 0 0 0 #f5222d;
}
.previewContainer .outerRadioContainer .radioContainer .radioGroup.danger :global(.ant-radio-button-wrapper-checked):hover {
  border-color: #ff7875;
  color: #ff7875;
  box-shadow: -1px 0 0 0 #ff7875;
}
.previewContainer .outerRadioContainer .radioContainer .radioGroup.danger :global(.ant-radio-button-wrapper-checked)::before {
  background-color: #f5222d !important;
}
.previewContainer .outerImageContainer {
  height: 0;
  padding-bottom: 66.67%;
  position: relative;
}
.previewContainer .outerImageContainer .imageContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}
.previewContainer .outerImageContainer .imageContainer .textContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  padding-top: 100px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
}
.previewContainer .outerImageContainer .imageContainer .textContainer.fullScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
.previewContainer .outerImageContainer .imageContainer .textContainer .closeButton {
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 2;
}
.previewContainer .outerImageContainer .imageContainer .textContainer .text {
  text-align: left;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding-left: 10%;
}
.previewContainer .outerImageContainer .imageContainer .textContainer .text::-webkit-scrollbar {
  background: none;
  width: 10px;
}
.previewContainer .outerImageContainer .imageContainer .textContainer .text::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
