.table {
  width: 100%;
  border: 1px solid #e8e8e8;
  line-height: 1.5;
  font-size: 14px;
  margin: 1em 0;
  text-align: center;
  background-color: #fff;
}
.table td,
.table th {
  border: 1px solid #e8e8e8;
  padding: 0.6em;
}
.table th,
.table td.th {
  background: rgba(0, 0, 0, 0.02);
  font-weight: bold;
  color: #5c6b77;
}
.table tr.compact td {
  padding: 5px;
}
.fakeInput {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  border-radius: 4px;
  cursor: pointer;
  transition: border 0.3s, background-color 0.3s;
  border: 1px solid rgba(255, 255, 255, 0);
}
.fakeInput:hover {
  border: 1px solid #e5e6eb;
  background-color: #fff;
}
.fakeInput.small {
  height: 24px;
  padding: 1px 7px;
}
.configRowGroup .cover {
  left: 0;
  position: absolute;
  transform: scale(1);
  width: 100%;
  transition: opacity 0.3s;
}
.configRowGroup .cover td {
  border: none;
  padding: 6px;
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  text-align: right;
  background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
}
.configRowGroup .cover td.blank {
  background: none;
}
.configRowGroup:hover .cover {
  opacity: 0;
}
.configRowGroup:hover .cover:hover {
  opacity: 1;
}
.questContainer {
  text-align: center;
  flex: none;
}
.questContainer .questInfo {
  margin: 1em 0;
}
.questContainer .tableActions {
  margin: 8px;
  text-align: left;
}
.enemyRowWithChange {
  border-left: 4px solid #4c84ff;
  cursor: pointer;
}
.enemyRowWithChange.rowShow {
  background: #f0f7ff;
}
