.sider {
  height: 100%;
  overflow: auto;
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
  border-right: 1px solid #e8e8e8;
}

.missionListContent {
  .ant-collapse-borderless {
    background: none;
  }
  .mission-type {
    float: right;
    margin-right: 40px;
  }
}

.listCard {
  background: #f5f6fa;
  line-height: 22px;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  padding: 12px 10px;
  margin: 0 0 10px 0;
  transition: all 0.5s ease-out;
  // color: #a5aabd;
  cursor: pointer;
  &:hover {
    box-shadow: 0 3px 15px 0 #e2e3e8;
    border: 1px solid #4c84ff;
  }
}

@border-color-base: #e5e6eb;@layout-body-background: #f5f6fa;@primary-color: #4c84ff;@table-selected-row-bg: #f5f6fa;