.header {
  background: #fff;
  padding: 0;
  border-bottom: 1px solid #e5e6eb;
  .logo {
    height: 64px;
    padding-left: 10px;
    transition: transform 0.3s ease-out;
    &:hover {
      transform: rotate(360deg);
    }
  }
  .headerMenu {
    float: right;
    line-height: 62px;
    border-bottom: none;
    margin-right: 50px;
  }
}

@border-color-base: #e5e6eb;@layout-body-background: #f5f6fa;@primary-color: #4c84ff;@table-selected-row-bg: #f5f6fa;