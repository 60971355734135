.unitRaritySider {
  border-right: 1px solid #e5e6eb;
  .unitRarityMenu {
    border-right: none;
  }
}

.unitListContent {
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 30px;
  position: relative;
  &.sider {
    padding: 0;
  }
}

.filterContainer {
  margin-top: 8px;
}

.sorterBlock {
  padding: 20px 10px;
  height: 64px;
  overflow: hidden;
  background: fade(#f5f6fa, 80%);
  transition: box-shadow 0.3s;
  &.shadow {
    box-shadow: 0 8px 8px -4px #e2e3e8;
  }
  z-index: 1;
  color: #a5aabd;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a5aabd;
    transition: color 0.5s ease-out;
    &:hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.listContainer {
  .listCard {
    background: white;
    &.male {
      background: linear-gradient(to right, #ffccc7, white 50%);
    }
    &.female {
      background: linear-gradient(to right, #bae7ff, white 50%);
    }
    line-height: 22px;
    border: 1px solid white;
    border-radius: 3px;
    padding: 8px;
    transition: all 0.5s ease-out;
    height: 64px;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 15px 0 #e2e3e8;
      border: 1px solid #4c84ff;
    }

    &.disable {
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
        box-shadow: none;
      }
      cursor: default;
      opacity: 0.8;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@border-color-base: #e5e6eb;@layout-body-background: #f5f6fa;@primary-color: #4c84ff;@table-selected-row-bg: #f5f6fa;