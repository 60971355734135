.content {
  text-align: center;
  .logoblock {
    .topLogo {
      width: 200px;
    }
  }
  .beg {
    margin-top: 16px;
    text-align: center;
    color: #40a9ff;
    del {
      cursor: pointer;
    }
    font-size: 1.5em;
  }
  .postContainer {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

.calendarMonthDisabled {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25);
  &:hover {
    background: none;
  }
  &:active {
    color: rgba(0, 0, 0, 0.25);
  }
}

@border-color-base: #e5e6eb;@layout-body-background: #f5f6fa;@primary-color: #4c84ff;@table-selected-row-bg: #f5f6fa;